<template>
    <dvi>
        <div>
            <Breadcrumb content="添加勘误"/>
        </div>
        <div class="m-bg m-pd">
            <el-form v-model="form" label-width="100px" >
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="标题">
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>
                        <el-form-item label="更新日期">
                            <el-input v-model="form.update"></el-input>
                        </el-form-item>
                        <el-form-item label="卡片编号">
                            <el-input v-model="form.card_no"></el-input>
                        </el-form-item>
                        <el-form-item label="卡片名称">
                            <el-input v-model="form.card_name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="图片">
                            <AvatarUpload @uploadSuccess="handleImgUpload"></AvatarUpload>
                            <div class="m-tips">图片大小需要长宽为200*291像素</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" :offset="1">
                        <el-form-item label="修正内容">
                            <Editor @getEditorVal="getEditorModify"></Editor>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" :offset="1">
                        <el-form-item label="修正理由">
                            <Editor @getEditorVal="getEditorModifyNotice"></Editor>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" :offset="1">
                        <el-form-item label="卡片切换">
                            <el-select v-model="form.card_change_show">
                                <el-option label="显示" value="1"></el-option>
                                <el-option label="隐藏" value="0"></el-option>
                            </el-select>
                            <Editor v-show="form.card_change_show > 0" @getEditorVal="getEditorCardChange"></Editor>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18" :offset="1">
                        <el-form-item label="卡片使用">
                            <Editor @getEditorVal="getEditorCardUse"></Editor>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item>
                            <el-button type="success" @click="add">立即保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </div>
    </dvi>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import AvatarUpload from "@/components/admin/avatar-upload"
    import Editor from '@/components/admin/editor'
    export default {
        name: "add",
        components:{Breadcrumb,AvatarUpload,Editor},
        data() {
            return {
                form: {
                    title:"",
                    update:"",
                    card_no:"",
                    card_name:"",
                    img:"",
                    modify_notice:"",
                    modify:"",
                    card_change:"",
                    card_use:"",
                    card_change_show:"0",
                }
            }
        },
        methods: {
            getEditorModify(v){
                this.form.modify = v;
            },
            getEditorModifyNotice(v){
                this.form.modify_notice = v;
            },
            getEditorCardChange(v){
                this.form.card_change = v;
            },
            getEditorCardUse(v){
                this.form.card_use = v;
            },
            add(){
                this.$axios.post("site/siteEratta/save",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");
                            setTimeout(()=>{
                                this.$router.push("erattas")
                            },1000)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
            handleImgUpload(obj){
                this.form.img = obj.imgUrl;
            }
        },
    }
</script>

<style scoped>

</style>
